import {
  Box,
  Container,
  Grid,
  GridItem,
  Heading,
  Text,
  Link,
  Stack,
  HStack,
  Button,
  Image,
  Flex,
  Spacer,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from '@chakra-ui/react'
import PayLeafNavbar from '../components/PayLeafNavbar'
import { Header } from '../modules/merchant/Header'

const faq = [
  {
    question: 'What is a payment solution?',
    answer:
      'We provide you with software that makes it easy for you to accept mobile payments over the Solana network. Without ever touching your funds.',
  },
  {
    question: 'What do I need to start?',
    answer: (
      <Text as='span'>
        You need a Solana crypto wallet. <br />
        With it, you will have complete control over your own funds. Create one,
        sign up at our website with it, and starting taking payments easily.
      </Text>
    ),
  },
  {
    question: 'How does it work?',
    answer: (
      <Text as='span'>
        After signing up, you will receive a link to your own POS website. On
        this website, you can can receive a payment in 3 easy steps.
        <br />
        1. Input the amount your customer should pay
        <br />
        2. Let him scan the QR code.
        <br />
        3. Wait a few seconds for the transaction to confirm.
      </Text>
    ),
  },
  {
    question: 'How can I pay?',
    answer: (
      <Text as='span'>
        As a user it's simple. You'll need the mobile crypto wallet called
        Phantom and enough PUFF, Solana or USDC in your wallet for the
        transaction.
        <br />
        1. Scan the QR code with the built in scanner
        <br />
        2. Confirm the transaction
        <br />
        3. Enjoy your goods
      </Text>
    ),
  },
  {
    question: 'How do I benefit as a Stoned Ape holder?',
    answer: (
      <Text as='span'>
        1. <b>PUFF</b> should <b>become the No1 cannabis-focused Token</b>.
        Instead of just us accepting PUFF, we will make sure the whole industry
        accepts it.
        <br />
        <br />
        2. We will <b>grow this payment platform with the community</b> and make
        sure all of our <b>holders benefit</b>! Our <b>ambassador program</b>{' '}
        also makes it possible for you to earn passive income by bringing
        PayLeaf to a dispensary or other retail location near you.
      </Text>
    ),
  },
  {
    question: 'Why join?',
    answer: (
      <Text as='span'>
        PayLeaf will <b>super charge</b> your operation and improve multiple day
        to day tasks.
        <br />
        We make a range of things easier for you:
        <br />- You don't need to manage cash.
        <br />- You can easily retarget your customers with ads, without even
        knowing their names.
        <br />- You save on fees in comparison to credit/debit cards.
        <br />
        <br />
        On top of that, you are adopting a global cannabis payment solution in
        web3 for broader acceptance of cannabis.
      </Text>
    ),
  },
  {
    question: 'Why should I care as a dispensary?',
    answer: (
      <Text as='span'>
        Peer-to-peer crypto payments in the Cannabis space solve the problems
        limiting the utilization of credit cards, but provide a safer mechanism
        of managing funds vs cash.
      </Text>
    ),
  },
  {
    question: 'Cryptos are volatile. Is there any risk?',
    answer: (
      <Text as='span'>
        Through our automatic swap system, you are able to automatically swap
        any token you receive to a stablecoin like USDC with stable value
        (pegged by the USD)
        <br />
        You can then transfer USDC to any crypto exchange and convert it to USD.
      </Text>
    ),
  },
]

export default function PayLeafIndex() {
  return (
    <Box
      minHeight={'100vh'}
      background='#F3F3F5'
      color='#262626'
      display={'flex'}
      flexDirection='column'
      justifyContent={'space-between'}
      fontFamily='Inter'
    >
      <Container maxW={'78.5rem'} pt='2rem' px='1rem' pb='3rem'>
        <PayLeafNavbar></PayLeafNavbar>
        <Grid
          templateColumns='repeat(10, 2fr)'
          gap='1.5rem'
          alignContent={'stretch'}
        >
          <GridItem
            colSpan={[10]}
            bg='white'
            borderRadius='10px'
            px={['2rem', '4rem']}
            py='3rem'
          >
            <Flex flexDirection={['column', 'row']}>
              <Box maxWidth={['100%', '100%', '65%']}>
                <Heading
                  fontSize={['2.4rem', '3.2rem']}
                  fontWeight={500}
                  fontFamily='Qualy'
                  my='1.5rem'
                >
                  Accept digital payments with PayLeaf
                </Heading>
                <Text mt='1rem' fontSize='1.2rem'>
                  We allow anyone to send and receive funds digitally using the blockchain. Send money to your friends. Accept payments at your store. Made Easy.
                </Text>

                <Text
                  fontWeight='500'
                  fontSize='1.5rem'
                  color='#9498A5'
                  my='1rem'
                >
                  Merchant Fee: 1.2% or less.
                </Text>

                <Text mt='1rem' fontSize='0.9rem'>
                  Built on Solana from
                  the team that brought you{' '}
                  <Link
                    href='https://www.stonedapecrew.com'
                    target='_blank'
                    textDecoration='underline'
                  >
                    Stoned Ape Crew
                  </Link>
                </Text>
                <Stack mt='2rem' direction={['column', 'row']}>
                  <Link href='/signup' target='_blank'>
                    <Button
                      bg='greenPay'
                      _hover={{ opacity: 0.9 }}
                      color='white'
                      border='none'
                      paddingX='1.8rem'
                    >
                      Get Started
                    </Button>
                  </Link>

                  <Link href='mailto:sales@payleaf.io'>
                    <Button
                      bg='transparent'
                      color='greenPay'
                      border='2px solid #06792D'
                      _hover={{ opacity: 0.9 }}
                      borderRadius='5px'
                    >
                      Contact Sales
                    </Button>
                  </Link>
                </Stack>
              </Box>
              <Spacer></Spacer>
              <Box display={['none', 'none', 'block']}>
                <Image mt='4rem' src='/images/Coins.svg'></Image>
              </Box>
            </Flex>
          </GridItem>

          <GridItem
            transition={'all 0.5s ease'}
            _hover={{
              transform: 'translateY(-5px)',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.16)',
            }}
            colSpan={[10, 5]}
            bg='white'
            borderRadius='10px'
          >
            <Link
              href='/merchant'
              display='block'
              px={['1rem', '2rem']}
              py='2rem'
              _hover={{}}
            >
              <Heading fontFamily='Roie'>For Merchants</Heading>
              <Text mt='.5rem' mb='1.5rem'>
                Mobile in-store payments made easy. <br />
                Take secure payments in USDC in your store.
              </Text>

              <Box fontFamily='Inter'>
                <Text
                  fontWeight='500'
                  fontSize='1.5rem'
                  color='#9498A5'
                  my='1rem'
                >
                  Simple and secure transactions.
                </Text>
                <Text
                  fontWeight='500'
                  fontSize='1.5rem'
                  color='#9498A5'
                  my='1rem'
                >
                  Your wallet - your funds.
                </Text>
                <Text
                  fontWeight='500'
                  fontSize='1.5rem'
                  color='#9498A5'
                  my='1rem'
                >
                  Avoid volatility. Automatic swaps to stablecoins.
                </Text>
              </Box>

              <Button
                variant='outline'
                display='inline-block'
                mt='1rem'
                padding={0}
                color='greenPay'
                fontWeight={600}
                _hover={{
                  textDecor: 'underscore',
                }}
              >
                Explore Merchant Benefits -{'>'}
              </Button>
            </Link>
          </GridItem>

          <GridItem
            transition={'all 0.5s ease'}
            _hover={{
              transform: 'translateY(-5px)',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.16)',
            }}
            colSpan={[10, 5]}
            bg='white'
            borderRadius='10px'
            px={['1rem', '2rem']}
            py='2rem'
          >
            <Link
              href='/app/signup'
              display='block'
              px={['1rem', '2rem']}
              py='2rem'
              _hover={{}}
            >
              <Heading fontFamily='Roie'>For Customers</Heading>
              <Flex flexDirection='row'>
                <Box>
                  <Text mt='.5rem' mb='1.5rem'>
                    Make easy payments with your Solana crypto wallet in
                    selected stores.
                    <br />
                    Giving your crypto more uses than ever before
                  </Text>
                  <Box fontFamily='Inter'>
                    <Text
                      fontWeight='500'
                      fontSize='1.5rem'
                      color='#9498A5'
                      my='1rem'
                    >
                      Fast.
                    </Text>
                    <Text
                      fontWeight='500'
                      fontSize='1.5rem'
                      color='#9498A5'
                      my='1rem'
                    >
                      Easy.
                    </Text>
                    <Text
                      fontWeight='500'
                      fontSize='1.5rem'
                      color='#9498A5'
                      my='1rem'
                    >
                      Secure.
                    </Text>
                    <Text
                      fontWeight='500'
                      fontSize='1.5rem'
                      color='#9498A5'
                      my='1rem'
                    >
                      Anonymous.
                    </Text>
                  </Box>
                </Box>
                <Spacer></Spacer>
                <Box maxWidth='200px' ml='1rem' mt='1rem'>
                  <Box borderRadius='10px' overflow='hidden'>
                    <video
                      src='/videos/payleaf.mp4'
                      autoPlay={true}
                      controls={false}
                      loop={true}
                    ></video>
                  </Box>
                </Box>
              </Flex>
              <Button
                display='inline-block'
                variant={'outline'}
                mt='1rem'
                padding={0}
                color='greenPay'
                fontWeight={600}
                _hover={{
                  borderBottom: '2px solid #06792D',
                }}
              >
                Get started -{'>'}
              </Button>
            </Link>

            {/* <Link
              href='/customer' 
              target='_blank'
              display='inline-block'
              mt='2rem'
              color='greenPay'
              fontWeight={600}
              _hover={{
                borderBottom: '2px solid #06792D'
              }}
            >
              Explore Customer Benefits -{'>'}
            </Link> */}
          </GridItem>

          <GridItem
            transition={'all 0.5s ease'}
            _hover={{
              transform: 'translateY(-5px)',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.16)',
            }}
            colSpan={[10, 5]}
            bg='white'
            borderRadius='10px'
          >
            <Link
              href='/ambassador'
              target='_blank'
              display='block'
              px={['1rem', '2rem']}
              py='2rem'
              _hover={{}}
            >
              <Heading fontFamily='Roie'>For Ambassadors</Heading>
              <Text mt='.5rem' mb='1.5rem'>
                Refer businesses, help them set up PayLeaf and earn commission
                from every payment made through PayLeaf. <br />
                Be part of the payment revolution, one store at a time.
              </Text>

              <Box fontFamily='Inter'>
                <Text
                  fontWeight='500'
                  fontSize='1.5rem'
                  color='#9498A5'
                  my='1rem'
                >
                  Commission from every store you sign up.
                </Text>
                <Text
                  fontWeight='500'
                  fontSize='1.5rem'
                  color='#9498A5'
                  my='1rem'
                >
                  Up to 17% base Commission on PayLeaf Revenue.
                </Text>
                <Text
                  fontWeight='500'
                  fontSize='1.5rem'
                  color='#9498A5'
                  my='1rem'
                >
                  Increase commissions with SAC NFTs.
                </Text>
              </Box>

              <Button
                display='inline-block'
                variant='outline'
                mt='1rem'
                padding={0}
                color='greenPay'
                fontWeight={600}
                _hover={{
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }}
              >
                Explore Ambassador Program -{'>'}
              </Button>
            </Link>
          </GridItem>
        </Grid>

        <Grid
          mt='1.5rem'
          templateColumns='repeat(10, 2fr)'
          gap='1.5rem'
          alignContent={'stretch'}
        >
          <GridItem
            transition={'all 0.5s ease'}
            _hover={{
              transform: 'translateY(-5px)',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.16)',
            }}
            colSpan={[10, 5]}
            bg='white'
            borderRadius='10px'
            px={['1rem', '2rem']}
            py='2rem'
          >
            <Heading fontFamily='Roie'>Merchant Dashboard</Heading>
            <Text mt='.5rem' mb='1.5rem'>
              All information at a glance with our merchant dashboard. Set up
              multiple stores, manage payments and configure products. All in
              one place.
            </Text>
            <Image
              src='/images/payLeaf-dashboard.png'
              borderRadius='5px'
              border='1px solid #eee'
            ></Image>
          </GridItem>

          <GridItem
            transition={'all 0.5s ease'}
            _hover={{
              transform: 'translateY(-5px)',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.16)',
            }}
            colSpan={[10, 5]}
            bg='white'
            borderRadius='10px'
            px={['1rem', '2rem']}
            py='2rem'
          >
            <Heading fontFamily='Roie' mb='1.5rem'>
              More Coming soon 👀
            </Heading>

            <Text fontWeight='500' fontSize='1.5rem' color='#9498A5' my='1rem'>
              PayLeaf V2 - the "Cash App" for web3
            </Text>
            <Text fontWeight='500' fontSize='1.5rem' color='#9498A5' my='1rem'>
              Webshop Integrations
            </Text>
            <Text fontWeight='500' fontSize='1.5rem' color='#9498A5' my='1rem'>
              Anonymous Retargeting
            </Text>
            <Text fontWeight='500' fontSize='1.5rem' color='#9498A5' my='1rem'>
              And more...
            </Text>

            <Text mt='2rem'>
              We are right now in our MVP beta testing phase & only available in
              selected locations to make payments as smooth as butter.
            </Text>

            <Text my='1rem'>
              When that's finished, we'll scale our solution all over the
              globe with the{' '}
              <Text fontWeight='bold' as='span'>
                help of over 2500 community members.
              </Text>{' '}
              And let them benefit!
            </Text>
          </GridItem>

          <GridItem
            colSpan={[10]}
            bg='white'
            borderRadius='10px'
            px={['2rem', '4rem']}
            py='3rem'
          >
            <Grid
              templateColumns={[
                'repeat(1, 1fr)',
                'repeat(1, 1fr)',
                'repeat(2, 1fr)',
              ]}
              gap='1rem'
            >
              <GridItem>
                <Heading fontFamily='Roie' mb='2rem'>
                  Pricing
                </Heading>
                <Text>Payments should be cheap. We make that work.</Text>
                <Text fontWeight='600'>No fees for customers.</Text>
              </GridItem>
              <GridItem>
                <Text
                  fontWeight='500'
                  fontSize='1.5rem'
                  color='#9498A5'
                  my='1rem'
                >
                  1.2% for USDC/SOL Transaction
                </Text>
                <Text
                  fontWeight='500'
                  fontSize='1.5rem'
                  color='#9498A5'
                  my='1rem'
                >
                  1% for PUFF Transaction
                </Text>
              </GridItem>
            </Grid>
          </GridItem>

          {/* <GridItem
            transition={'all 0.5s ease'}
            _hover={{
              transform: 'translateY(-5px)',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.16)',
            }}
            colSpan={[10, 5]}
            bg='white'
            borderRadius='10px'
            px={['1rem', '2rem']}
            py='2rem'
          >
            <Heading fontFamily='Roie' mb='2rem'>
              Pricing
            </Heading>
            <Text>Payments should be cheap. We make that work.</Text>
            <Text fontWeight='600'>No fees for customers.</Text>
            <Text fontWeight='500' fontSize='1.5rem' color='#9498A5' my='1rem'>
              1.2% per USDC/SOL Transaction
            </Text>
            <Text fontWeight='500' fontSize='1.5rem' color='#9498A5' my='1rem'>
              1% per PUFF Transaction
            </Text>
          </GridItem> */}

          <GridItem
            colSpan={[10]}
            px={['1rem', '2rem']}
            mt='2rem'
            textAlign='center'
          >
            <Text fontFamily='Roie' fontSize='1.2rem' my='1.5rem'>
              Become a part of the payment solution of the future.
            </Text>
            <Link href='/signup'>
              <Button
                bg='greenPay'
                _hover={{ opacity: 0.9 }}
                color='white'
                border='none'
                mt='1.2rem'
              >
                Sign up
              </Button>
            </Link>
          </GridItem>

          <GridItem
            colSpan={[10]}
            px={['1rem', '2rem']}
            mt='0rem'
            mb='1rem'
            textAlign='center'
          >
            <Text fontFamily='Roie' fontSize='1.2rem' my='1.5rem'>
              See how a payment works
            </Text>
            <Link href='/pay/demo'>
              <Button
                bg='greenPay'
                _hover={{ opacity: 0.9 }}
                color='white'
                border='none'
                mt='1.2rem'
              >
                Try the public demo
              </Button>
            </Link>
          </GridItem>

          <GridItem
            colSpan={[10]}
            bg='white'
            borderRadius='10px'
            px={['1rem', '2rem']}
            py='2rem'
          >
            <Flex direction={['column', 'row']}>
              <Heading
                fontFamily='Roie'
                display={['block', 'none']}
                paddingLeft='1rem'
                mb='2rem'
              >
                Any Questions?
              </Heading>

              <Accordion
                defaultIndex={[0]}
                allowMultiple
                width={['100%', '60%']}
              >
                {faq.map((f, i) => (
                  <AccordionItem
                    key={f.question}
                    borderBottom={
                      i === faq.length - 1 ? 'none' : '1px solid #ddd'
                    }
                  >
                    <h2>
                      <AccordionButton>
                        <Box flex='1' textAlign='left'>
                          <i>{f.question}</i>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>{f.answer}</AccordionPanel>
                  </AccordionItem>
                ))}
              </Accordion>
              {/* <Spacer/> */}
              <Box position='relative' width='40%' display={['none', 'block']}>
                <Heading
                  fontFamily='Roie'
                  position='absolute'
                  top='50%'
                  left='50%'
                  translateX='-50%'
                  transform='translate(-50%, -50%)'
                >
                  Any Questions?
                </Heading>
              </Box>
            </Flex>
          </GridItem>
        </Grid>
      </Container>
    </Box>
  )
}
