import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons'
import {
  Button,
  Flex,
  Link,
  Image,
  Stack,
  Box,
  IconButton,
  useDisclosure,
  useOutsideClick,
  Collapse,
  Spacer,
} from '@chakra-ui/react'
import { useRef } from 'react'

export default function PayLeafNavbar() {
  const rootRef = useRef<HTMLDivElement>(null)
  const { isOpen, onToggle, onClose } = useDisclosure()

  const LINKS = [
    { title: 'User App', href: '/app', newTab: false },
    { title: 'For Merchants', href: '/merchant', newTab: false },
    { title: 'Ambassadors', href: '/ambassador', newTab: true },
    { title: 'Guides', href: '/guides', newTab: false },
  ]

  useOutsideClick({
    ref: rootRef,
    handler: () => onClose(),
  })

  return (
    <Box ref={rootRef} mb='1rem'>
      <Stack color='#000' direction='row' display={['none', 'none', 'flex']}>
        <Link href='/' flexShrink={0}>
          <Button
            bg='white'
            border='none'
            fontSize='.75rem'
            transition={'all 0.5s ease'}
            _hover={{
              transform: 'translateY(-5px)',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.16)',
            }}
          >
            <Image
              src='images/payleaf-logo-with-text.png'
              height='1.2rem'
            ></Image>
          </Button>
        </Link>

        {LINKS.map((link) => (
          <Link
            key={link.href}
            href={link.href}
            target={link.newTab ? '_blank' : '_self'}
          >
            <Button
              bg='white'
              border='none'
              fontSize='1rem'
              transition={'all 0.5s ease'}
              fontFamily='Qualy'
              fontWeight='100'
              _hover={{
                transform: 'translateY(-5px)',
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.16)',
              }}
            >
              {link.title}
            </Button>
          </Link>
        ))}

        <Spacer></Spacer>
        <Link href='/app/signup'>
          <Button
            bg='offblack'
            color='white'
            border='none'
            fontSize='1rem'
            transition={'all 0.5s ease'}
            fontFamily='Qualy'
            fontWeight='100'
            _hover={{
              transform: 'translateY(-5px)',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.16)',
            }}
          >
            User Login
          </Button>
        </Link>
        <Link href='/dashboard'>
          <Button
            bg='offblack'
            color='white'
            border='none'
            fontSize='1rem'
            transition={'all 0.5s ease'}
            fontFamily='Qualy'
            fontWeight='100'
            _hover={{
              transform: 'translateY(-5px)',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.16)',
            }}
          >
            Merchant Login
          </Button>
        </Link>
      </Stack>

      <Flex justifyContent='space-between' display={['flex', 'flex', 'none']}>
        <Link href='/' flexShrink={0}>
          <Button
            bg='white'
            border='none'
            fontSize='.75rem'
            transition={'all 0.5s ease'}
            _hover={{
              transform: 'translateY(-5px)',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.16)',
            }}
          >
            <Image
              src='images/payleaf-logo-with-text.png'
              height='1.2rem'
            ></Image>
          </Button>
        </Link>
        <IconButton
          onClick={onToggle}
          icon={
            isOpen ? <CloseIcon w={4} h={4} /> : <HamburgerIcon w={4} h={4} />
          }
          backgroundColor='white'
          color='black'
          border='none'
          isRound
          aria-label={'Toggle Navigation'}
          height='2.5rem'
          width='2.5rem'
        />
      </Flex>

      <Collapse in={isOpen} unmountOnExit>
        <Stack color='#000' direction='column' mt='1rem'>
          {LINKS.map((link) => (
            <Link
              key={link.href}
              href={link.href}
              target={link.newTab ? '_blank' : '_self'}
            >
              <Button
                bg='white'
                border='none'
                fontSize='1rem'
                transition={'all 0.5s ease'}
                fontFamily='Qualy'
                fontWeight='100'
                _hover={{
                  transform: 'translateY(-5px)',
                  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.16)',
                }}
              >
                {link.title}
              </Button>
            </Link>
          ))}

          <Link href='/app/signup'>
            <Button
              bg='offblack'
              color='white'
              border='none'
              fontSize='1rem'
              transition={'all 0.5s ease'}
              fontFamily='Qualy'
              fontWeight='100'
              _hover={{
                transform: 'translateY(-5px)',
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.16)',
              }}
            >
              User Login
            </Button>
          </Link>
          <Link href='/dashboard'>
            <Button
              bg='offblack'
              color='white'
              border='none'
              fontSize='1rem'
              transition={'all 0.5s ease'}
              fontFamily='Qualy'
              fontWeight='100'
              _hover={{
                transform: 'translateY(-5px)',
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.16)',
              }}
            >
              Merchant Login
            </Button>
          </Link>
        </Stack>
      </Collapse>
    </Box>
  )
}
